import "./App.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import Dashboard from "./Components/Dashboard";
import Header from "./Components/header";
import CreateQuiz from "./Components/createEditQuiz";
import DeletionPopup from "./Components/deletionPopup";
import { useSelector } from "react-redux";
import ActivateGame from "./Components/activateGame";
import Statistics from "./Components/statistics";
import PasswordReset from "./Components/passwordReset";
// import CreateEditAdmin from "./Components/createEditAdmin";
import Login from "./Components/login";
import ResultsPopup from "./Components/resultPopup";
import { useEffect } from "react";
function App() {
  const deletionPopup = useSelector((state) => state.main.deletionPopupIsOpen);
  const resultsPopupIsOpen = useSelector(
    (state) => state.main.resultsPopupIsOpen
  );

  const routes = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/create-quiz" element={<CreateQuiz />} />
        <Route path="/activate-game" element={<ActivateGame />} />
        <Route path="/game-stats" element={<Statistics />} />
        <Route path="/login" element={<Login />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/*" element={<Navigate to="/login" />} />
      </>
    )
  );

  return (
    <>
      <div
        className={deletionPopup || resultsPopupIsOpen ? "popup-open" : "App"}
      >
        <Header />
        <small style={{ color: "#fff" }}>
          You are running this application in <b>DEV</b>{" "}
          mode.
        </small>
        <RouterProvider router={routes} />
      </div>
      {/*{process.env.REACT_APP_APIURL}*/}
      {deletionPopup && <DeletionPopup />}
      {resultsPopupIsOpen && <ResultsPopup />}
    </>
  );
}

export default App;
